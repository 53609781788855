import store from '@/store'
import axios from 'axios'
import { message } from 'ant-design-vue';
import router from "../router/index.js";
axios.defaults.withCredentials = false //跨域
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://fengapi.dabhkwurotbajcdsmebal.com/api/',
  // 超时
  timeout: 60000,
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false
    config.headers['domain'] = document.domain;
    if (localStorage.getItem('token') && !isToken) {
      config.headers['Authorization'] = localStorage.getItem('token')
    }
    if (localStorage.getItem('App-Language') && !isToken) {
      config.headers['lang'] = localStorage.getItem('App-Language') || 'en'
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  },
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 1
    if(res.data.code == 999){
      message.error(res.data.msg);
      router.push("/login");
      return
    }
    return res.data
  },
  (error) => {
    //loading.close()
    if (error.message == 'Network Error') {
      error.message = 'Network Error'
    } else if (error.message.includes('timeout')) {
      error.message = 'Network Error'
    } else if (error.message.includes('Request failed with status code')) {
      error.message = 'Network Error'
    }
    
    message.error(error.message);
    return Promise.reject(error)
  },
)

export default service